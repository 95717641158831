import { useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.sass";
import { Input, Table, Spin, Alert } from "antd";
import { columns } from "./columns";
import "./style.less";
import { Search, Download } from "react-feather";
import { Queries } from "../../../../../Queries/Manager";
import { useSelect } from "../../../../../hooks/useSelect";
import { LoadingOutlined } from "@ant-design/icons";
import { useIsVisible } from "react-is-visible";
import { CustomerInsightsFilters } from "./Filters";
import { useDispatch } from "react-redux";
import { getBranches, getStore } from "../../../../../redux-store/actions";
import ProfileModal from "../../../../../Components/Leaderboard/ProfileModal";
import Strings from "../../../../../i18n/Manager/strings/insights";
import { useTranslation } from "../../../../../shopx-shared-components/hooks/useTranslation";
import { numberFormatter } from "../../../../../Utils/numbers";
import { trackEvent } from "../../../../../tracking/trackEvent";
import { EVENTS_FOR_TRACKING } from "../../../../../constants/events-for-tracking";
import {
  AmplitudeEvents,
  amplitudeLogEvent,
} from "../../../../../tracking/amplitude";
import StoreData from "../../../../../contexts/StoreData";
import { CSVButton, GenericExportCSVModal as ExportCSVModal } from "../components";
import { uploadCustomerPoints, uploadCustomerPointsIdle } from "../../../../../Components/Pickup/redux-store/actions/customer-actions";
import images from "../../../../../assets/images/images";
import { FileUploadedSuccessfully } from "../../../../../Components/FileUploadedSuccessfully";

export enum CustomerType {
  appUser = "app_user",
  nonAppUser = "unregistered_user",
}

export enum Gender {
  male = "male",
  female = "female",
}

export interface TimeKeys {
  last_visit_timestamp?: string;
}

export interface Filters {
  page: number;
  size: number;
  filter?: string;
  age: number[];
  average_ticket_size: number[];
  visits_count: number[];
  total_points: number[];
  branch_ids?: any[];
  last_visit_timestamp?: number[];
  customer_type?: CustomerType;
  redemptions_count?: "1+" | "0";
  gender?: Gender;
  include_non_aged: number;
  sort_by?:
  | "online_orders_count"
  | "points_operation_count"
  | "current_points"
  | "life_time_points";
  sort_order?: "asc" | "desc";
}

const CustomerInsightsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { token } = useSelect((state) => state.authReducer);
  //
  const listPaginationLoaderRef = useRef(null);
  const isVisible = useIsVisible(listPaginationLoaderRef);
  //
  const [isMore, setIsMore] = useState<boolean>(false);
  const [customerPopup, setCustomerPopup] = useState<boolean>(false);
  const [customerPopupId, setCustomerPopupId] = useState<string | undefined>();
  const [filterSearchText, setFilterSearchText] = useState<string>("");
  const [searchInputLoading, setSearchInputLoading] = useState<boolean>(false);
  const { storeData, userData } = useContext(StoreData);
  const [customersData, setCustomersData] = useState<any[] | undefined>(
    undefined
  );
  const [isExpCSVModalVisible, setExpCSVModalVisible] = useState(false);

  // localization
  const { t } = useTranslation("insights");
  const initialFiltersValue = {
    page: 0,
    size: 20,
    average_ticket_size: [0, 10000],
    total_points: [0, 50000],
    visits_count: [0, 500],
    age: [16, 65],
    branch_ids: [],
    include_non_aged: 1,
  };

  const [filters, setFilters] = useState<Filters>(initialFiltersValue);
  const [timeKeys, setTimeKeys] = useState<TimeKeys>({});

  // Query
  const { data, status } = Queries.useGetCustomerInsights(token, filters);
  const { data: totalCustomers, status: totalCustomersStatus } =
    Queries.useGetCustomerInsightsTotal(token, {
      ...filters,
      page: undefined,
      size: undefined,
    });

  useEffect(() => {
    trackEvent(EVENTS_FOR_TRACKING["Viewed customers insights page"]);
    amplitudeLogEvent(AmplitudeEvents.customers_insights, {
      user_name: userData?.name,
      user_id: userData?.id,
      user_role: userData?.title || userData?.role,
      store_id: storeData?.id,
      store_name: storeData?.name,
    });
  }, []);

  useEffect(() => {
    if (customerPopupId) setCustomerPopup(true);
    else setCustomerPopup(false);
  }, [customerPopupId]);

  useEffect(() => {
    if (status === "success" && data.length > 0) {
      if (filters.page === 0) {
        setCustomersData(data);
      } else {
        setCustomersData((prev) => (prev && [...prev, ...data]) || data);
      }

      if (data.length < filters.size) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
    } else {
      //
      if (filters.page === 0) {
        setCustomersData([]);
      }

      setIsMore(false);
    }
  }, [status, data]);

  useEffect(() => {
    if (status !== "loading") {
      setSearchInputLoading(false);
    }
  }, [status, data, filters.page]);

  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [attemptedUpload, setAttemptedUpload] = useState<boolean>(false);

  // set new filter update (with page reset to first page)
  // modification: if you need to change one filter then pass the args as ("key", val),
  // else if you need to change multiple filters at once, you need to pass the args as (["key1", "key2"], [val1, val2]),
  // because calling setFilterValue multiple times will only succeed to update the state once
  const setFilterValue = (
    keys: string[] | string,
    values: string[] | string
  ) => {
    const copyOfFilters = { ...filters };
    copyOfFilters["page"] = 0;
    if (typeof keys === "string") {
      copyOfFilters[keys] = values === "" ? undefined : values;
    } else if (Array.isArray(keys) && Array.isArray(values)) {
      keys.forEach((key, index) => {
        copyOfFilters[key] = values[index];
      });
    }
    setFilters(copyOfFilters);
  };

  const setTimeKeysValue = (key, value) => {
    const copyOfTimeKeys = { ...timeKeys };
    copyOfTimeKeys[key] = value;
    setTimeKeys(copyOfTimeKeys);
  };
  const uploalCustomerPointsFile = () => {
    if (fileToUpload) {
      setAttemptedUpload(true);
      const formPayload = new FormData();
      formPayload.append("data", fileToUpload);
      dispatch(uploadCustomerPoints(formPayload))
    }
  }
  const resetFilters = () => {
    setFilters(initialFiltersValue);
    setTimeKeys({});
  };

  // Get store with branches .. for "window refresh"
  useEffect(() => {
    dispatch(getStore());
    dispatch(getBranches());
  }, [dispatch]);

  // When bottom loader is visibile
  useEffect(() => {
    if (isMore && isVisible && status !== "loading") {
      setFilters((filters) => ({
        ...filters,
        page: filters.page + 1,
      }));
    }
  }, [isVisible, status]);

  // Apply text search
  const applySearchFilter = (e) => {
    e.target.blur();
    if (filterSearchText !== filters.filter) {
      setFilters({
        ...filters,
        page: 0,
        filter: filterSearchText !== "" ? filterSearchText : undefined,
      });
      setSearchInputLoading(true);
    }
  };

  const SearchIconJSX = (
    <Search color="#8E8E93" size={18} className={styles.searchInputIcon} />
  );
  const [downloadButtonStatus, setDownloadButtonStatus] = useState<
    "default" | "loading" | "disabled"
  >("default");

  const [socketExportStatus, setSocketExportStatus] = useState<
    "failed" | "default"
  >("default");
  const defaultCSVExample = require("../../../../../exmples/Bulk_Points_Upload_Template.csv").default;
  const CustomSpinner = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  const handleTableChange = (pagination, filter, sorter) => {
    const { order, column } = sorter;

    const sort_by = column ? column.key : undefined;

    const sort_order =
      order === "ascend" ? "asc" : order === "descend" ? "desc" : undefined;

    setFilters((prevState) => ({
      ...prevState,
      sort_order,
      sort_by,
    }));
  };

  const onCancel = () => {
    setExpCSVModalVisible(false);
    setDownloadButtonStatus("default");
    setFileToUpload(null);
    setAttemptedUpload(false);
    dispatch(uploadCustomerPointsIdle());
  }

  return (
    <div className={styles.pageWrapper}>
      <ExportCSVModal
        isVisible={isExpCSVModalVisible}
        uploadFile={(formData: FormData) => {
          setFileToUpload(formData.get('data') as File)
        }}
        onCancel={onCancel}
        showFileUploader={(!fileToUpload && !attemptedUpload) || (!!fileToUpload && attemptedUpload)}
        ExtraBody={(!!fileToUpload && !attemptedUpload) ? (({ resetFile }) => (
          <FileUploadedSuccessfully
            fileName={fileToUpload.name}
            onCancel={onCancel}
            onResetFile={resetFile}
            onUploadPoints={uploalCustomerPointsFile}
          />
        )) : undefined}

      >
        <CSVButton
          onClick={() => { }}
          buttonStatus={downloadButtonStatus}
          style={{ width: "504px", height: "48px" }}
        >
          <a className={styles.download_file_link} href={defaultCSVExample}>
            {"Download CSV file"}
          </a>
        </CSVButton>
      </ExportCSVModal>
      <div className={styles.heading}>
        <span className={styles.headingText}>
          {t(Strings.customers)}
        </span>
        <button className={styles.add_btn} onClick={() => {
          setExpCSVModalVisible(true);
        }}>
          <img src={require("../../../../../assets/download.svg").default} alt="" />
          Add Customers
        </button>
      </div>
      <div className={styles.tableWrapper}>
        <div className={styles.tableHead}>
          <div className={styles.tableHeadRowOne}>
            <div className={styles.title}>
              {totalCustomersStatus === "success" &&
                t(Strings.all_customers, {
                  customer_counts: numberFormatter(
                    Number(totalCustomers?.total)
                  ),
                })}
            </div>

            <div className={styles.searchAndActions}>
              <div className={styles.search}>
                <Input
                  className="insights-header-search-input"
                  size="small"
                  placeholder={t(Strings.search)}
                  value={filterSearchText}
                  onPressEnter={applySearchFilter}
                  onChange={(e) => setFilterSearchText(e.target.value)}
                  disabled={searchInputLoading}
                  suffix={
                    searchInputLoading && <Spin indicator={CustomSpinner} />
                  }
                  prefix={SearchIconJSX}
                />
              </div>
            </div>
          </div>
          {/* Filters */}
          <CustomerInsightsFilters
            filters={filters}
            setFilters={setFilters}
            setFilterValue={setFilterValue}
            timeKeys={timeKeys}
            setTimeKeys={setTimeKeys}
            setTimeKeysValue={setTimeKeysValue}
            resetFilters={resetFilters}
            initialValues={initialFiltersValue}
          />
          {/* end filters */}
        </div>
        <div className={styles.tableBody} id="customer-list">
          <Table
            columns={columns}
            rowClassName={"insights-table-row"}
            pagination={false}
            loading={
              status === "loading" && filters.page === 0
                ? { indicator: CustomSpinner }
                : false
            }
            dataSource={customersData}
            onRow={(row) => ({
              onClick: () => {
                setCustomerPopupId(row.customer_id);
              },
            })}
            onChange={handleTableChange}
          />

          {
            <div
              ref={listPaginationLoaderRef}
              className={`${isMore && styles.tableLoader}`}
            >
              {isMore && <Spin indicator={CustomSpinner} />}
            </div>
          }

          {customerPopup && customerPopupId && (
            <ProfileModal
              customer={{ id: customerPopupId }}
              close={() => {
                setCustomerPopupId(undefined);
              }}
              modal={customerPopup}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerInsightsPage;
