import axios from "./axios";
import * as Requests from "./requests";
import { AxiosReturn } from "./types";
import { ICashier } from "./getCashiers";

export interface IEditCashierData {
  password?: string;
  integration_id?: string;
  branch_ids?: string[];
  phoneNumber?: string;
  name?: string;
  id: string;
}

export const editCashier = (
  token,
  cashier: IEditCashierData
): AxiosReturn<ICashier> => {
  return axios.put(Requests.cashiers + "/" + cashier.id, cashier, {
    headers: {
      token,
    },
  });
};
