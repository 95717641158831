import convertingRatio from "./convertingRatio";
import sl from "./socialLinks";

export default {
  actualDeliveryFees: "مصاريف التوصيل الحقيقه",
  errorMessage:
    "بيانات الفرع غير مكتملة ، برجاء اكمال البيانات قبل إضافة مناطق التوصيل",
  syncOrderingMenuSuccess: "جاري المزامنة...",
  brandSavedSuccess: "تم حفظ بيانات المطعم بنجاح",
  syncOrderingMenuFailure: "عملية المزامنة قيد التشغيل بالفعل",
  addBranchTitle: "إضافة فرع جديد",
  branchNameLabel: "اسم الفرع",
  branchNamePlaceholder: "Branch Name",
  arBranchNameLabel: "الاسم باللغة العربية",
  arBranchNamePlaceholder: "الاسم",
  phoneNumberLabel: "رقم الهاتف",
  phoneNumberPlaceholder: "19000",
  syncBranchesAndCashier: "مزامنة الفروع والكاشيرات",
  integrationIdLabel: "رقم التكامل",
  enterTheLocationManually: "ادخل العنوان يدويا",
  longitude: "خط الطول",
  latitude: "خط العرض",
  integrationIdPlaceholder: "12",
  searchForDistrict: "البحث عن مدينة...",
  searchForArea: "البحث عن منطقة عامة",
  addressLabel: "العنوان",
  addressPlaceholder: "البحث عن مكان...",
  addBranch: "إضافة فرع",
  title: "الفروع",
  addressWithoutMapPlaceholder: "22 street name, square, etc.",
  arAddressWithoutMapPlaceholder: "٢٢ اسم الشارع، الحي، الخ",
  arAddressWithoutMapLabel: "العنوان باللغة العربية",
  addressWithoutMapLabel: "العنوان",
  subtitle: "أضف فروع متجرك بالأسفل:",
  branchesCount: "عدد الفروع ({{count}})",
  branchSearchPlaceholder: "البحث عن فرع...",
  newBranch: "إضافة فرع جديد",
  callCenterToggleTitle: "كول سنتر؟",
  progressAutoSaved: convertingRatio.progressAutoSaved,
  markComplete: convertingRatio.markComplete,
  noBranchesAdded: "لم يتم إضافة أي فرع",
  noMatch: "لا توجد نتائج",
  addBranchSuccess: 'تم إضافة فرع "{{branchName}}" بنجاح!',
  addBranchZonesSuccess: "تم تحديث مناطق توصيل الفرع بنجاح!!",
  addBranchZonesLoading: "جاري تحديث مناطق توصيل الفرع...",
  addBranchZonesFailure:
    "فشل تحديث مناطق توصيل الفرع، برجاء المحاولة مجدداً، اذا استمرت المشكلة برجاء التواصل مع الدعم.",
  addBranchFailure: 'فشل إضافة فرع "{{branchName}}"! حاول مجدداً',
  initializeBranchFailed: "خطأ في تحميل بيانات الفروع، برجاء اعادة المحاولة",
  brandSettings: " اعدادات المتجر",
  somethingWentWrong: convertingRatio.somethingWentWrong,
  deleteBranch: "حذف الفرع",
  deleteBranchDescription: "أنت على وشك حذف هذا الفرع، قم بالتأكيد للاستمرار.",
  editBranchTitle: "تعديل معلومات الفرع",
  submit: sl.submit,
  callCenterTitle: "هل لديك كول سنتر؟ 📞",
  callCenterDesc: "سيقوم هذا الخيار بإضافة فرع كول سنتر لمتجرك",
  callCenterSeeCashiers: "اضغط هنا لرؤية الكاشيرات",
  assignedCashiers: "الكاشيرات",
  submitAddBranch: "أضف الفرع",
  show: "عرض",
  more: "المزيد",
  less: "أقل",
  editBranch: "تعديل الفرع",
  viewBranchTitle: "عرض الفرع",
  addBranchButtonTitle: "اضافة الفرع",
  deleteUser: "حذف الفرع",
  deleteUserDescription: "أنت على وشك حذف هذا الفرع، قم بالتأكيد للاستمرار",
  confirmDelete: "تأكيد",
  cancel: "إلغاء",
  government: "المحافظة او الولاية",
  addDeliveryZones: "أضف مناطق التوصيل",
  zonesAdded: "تم إضافة مناطق",
  atLeastOneZone:
    " يجب عليك إضافة منطقة توصيل واحدة على الأقل لتفعيل نظام التوصيل",
  orderingStatus: "يقبل الطلبات",
  addZone: "اضف المناطق",
  enabled: "مفعل",
  disabled: "موقوف",
  deliverySystem: "نظام التوصيل",
  deliverySystemContent: "سيقوم مطعمك بتوصيل الطلبات التي تم طلبها لعملائك.",
  pickupSystem: "نظام الطلبات",
  pickupSystemContent: "يأتي العملاء ويأخذون طلباتهم من المطعم.",
  editDeliveryZones: "تعديل مناطق التسليم",
  district: "مدينة",
  area: "منطقة عامة",
  zone: "منطقة توصيل",
  editZone: "تعديل المناطق",
  branchInfo: "معلومات الفرع",
  deliveryZone: "مناطق التوصيل",
  deliveryFees: "مصاريف التوصيل",
  deliveryTime: "وقت التوصيل  ",
  zoneName: "اسم المنطقة",
  pound: "جنية",
  time: "دقيقة",
  apply: "وضع",
  checkAll: "اختر الكل",
  unCheckAll: "الغي اختيار الكل ",
  addAllDeliveryTimeAndFees: "أضف جميع المصاريف ومواعيد و تكلفه التوصيل",
  zones: "مناطق",
  deleteDistrictZonesDescription:
    "انت على وشك حذف هذه المنطقة ومناطق التوصيل الخاصة بها، قم بالتأكيد للاستمرار.",
  deleteDistrictZones: "حذف المنطقة",
  selectZonesBeforeFillingSelected:
    "رجاءاً قم باختيار المناطق التي تود ان تطبق عليها وقت ورسوم توصيل عامة",
  zonesWithNoArea: "احياء بلا مناطق",
  mayContainMultipleZones: "قد تحتوي علي اكتر من حي",
  restaurantHeading: "توصيل من خلال المطعم",
  restaurantParagraph: "سيقوم المطعم بتوصيل الطلب الى العميل",
  koinzDeliveryHeading: "توصيل من خلال كوينز",
  koinzDeliveryParagraph: "سيقوم مندوب كوينز بتوصيل الطلب الى العميل",
  offlineLoyaltyStatus: "حالة نظام الولاء محليًا",
};
