import axios from "./axios";
import * as Requests from "./requests";
import { AxiosReturn } from "./types";
import { ICashier } from "./getCashiers";

export interface ICreateCashier {
  password: string;
  integration_id?: string;
  branch_ids: string[];
  phoneNumber: string;
  name: string;
}

export const createCashier = (
  token,
  cashier: ICreateCashier
): AxiosReturn<{ cashier: ICashier }> => {
  return axios.post(Requests.cashiers, cashier, {
    headers: {
      token,
    },
  });
};
