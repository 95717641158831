import * as React from "react";
import styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import Drawer from "@material-ui/core/Drawer";
import { translate } from "react-i18next";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { RenderGenericField } from "../../../FormElements/RenderGenericField";
import {
  isEmpty,
  maxLength,
  minLength,
  required,
  arrayNotEmpty,
} from "../../../../redux-store/validators";
import { FormEvent } from "react";
import { Dispatch } from "redux";
import { passOnFormValues as passOnFormValuesRequest } from "../../../../redux-store/actions/passOnFormValuesActions";
import { connect } from "react-redux";
import { InjectedTranslateProps } from "react-i18next";
import i18n from "../../../../i18n/i18n";
import Strings from "../../../../i18n/strings/cashiers";
import { RenderPasswordField } from "../../../FormElements/RenderPasswordField";
import { RenderChipsField } from "../../../FormElements/RenderChipsField";
import { IRootReducerState } from "../../../../redux-store/rootReducer";
import TabItem from "../../../TabNavigation/components/TabItem";
import TabNavigation from "../../../TabNavigation";
import { ICreateCashier } from "../../../../axios/createCashier";
import { IEditCashierData } from "../../../../axios/editCashier";
import { FormTypes } from "../Cashiers/cashiers-hooks";
import { ClipLoader } from "react-spinners";
import { noop } from "../../../../Utils";
import { IBranch } from "../../../../types/shared";

export interface ICashierFormFields {
  name: string;
  phoneNumber: string;
  integration_id?: string;
  branches: string[];
  password: string;
  call_center?: 1;
}

interface IDispatchProps {
  passOnFormValues: (fn) => void;
}

interface IProps extends IDispatchProps {
  hasPOS: boolean;
  isOpen: boolean;
  toggleDrawer: () => void;
  onSubmit: (
    formValues: ICreateCashier | IEditCashierData,
    isCallCenter?: boolean
  ) => void;
  branches: IBranch[];
  formType: FormTypes;
  extraFieldsProps: {
    name: React.HTMLAttributes<HTMLInputElement>;
    phoneNumber: React.HTMLAttributes<HTMLInputElement>;
    branches: React.HTMLAttributes<HTMLInputElement>;
    password: React.HTMLAttributes<HTMLInputElement>;
    integration_id: React.HTMLAttributes<HTMLInputElement>;
  };
  readOnly?: boolean;
  submittingData?: boolean;
  titleText?: string;
  buttonText?: string;
  dismissCashierForm: () => void;
  hasCallCenterBranch: boolean;
}

const maxLength70 = maxLength(70);

const AddCashier: React.FC<
  InjectedFormProps<ICashierFormFields> & InjectedTranslateProps & IProps
> = (props) => {
  const {
    initialize,
    toggleDrawer,
    isOpen,
    titleText,
    buttonText,
    t,
    branches,
    readOnly,
    extraFieldsProps,
    formType,
    initialValues,
    destroy,
    change,
    submittingData,
    touch,
    valid,
    passOnFormValues,
    onSubmit,
    dirty,
    hasCallCenterBranch,
    hasPOS,
  } = props;
  React.useEffect(() => destroy, []);
  React.useEffect(() => {
    const vals = initialValues || {
      name: "",
      branches: [],
      integration_id: "",
      password: "",
    };
    change("name", vals.name);
    change("integration_id", vals.integration_id);
    change("branches", vals.branches);
    change("password", "");
  }, [props.initialValues]);

  React.useEffect(() => () => change("phoneNumber", ""), []);

  const handleAddCashierSubmit = (e: FormEvent) => {
    e.preventDefault();
    touch("name", "phoneNumber", "integration_id", "branches", "password");
    if (valid && (dirty || formType === "RESTORE")) {
      passOnFormValues(({ addCashierForm }) => {
        if (onSubmit) {
          const { values } = addCashierForm;
          onSubmit(
            {
              branch_ids: isCallCenter
                ? undefined
                : (values.branches || []).map((a) => a.id),
              id: values.id,
              integration_id: values.integration_id,
              name: values.name,
              password: values.password,
              phoneNumber: values.phoneNumber,
            },
            isCallCenter
          );
        }
      });
      // } else {
      //   dismissCashierForm();
    }
  };

  const handleToggleDrawer = () => toggleDrawer();

  const resetForm = () => initialize({});
  const [isCallCenter, setIsCallCenter] = React.useState(false);

  React.useEffect(() => {
    setIsCallCenter(Boolean((initialValues || {}).call_center));
  }, [(initialValues || {}).call_center]);
  const handleChangeTab = (tabIndex: number) => {
    resetForm();
    setIsCallCenter(tabIndex === 1);
  };

  const handleDisableEnter = React.useCallback((e: React.KeyboardEvent) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      e.preventDefault();
      return false;
    }
    return undefined;
  }, []);
  const formIsAdd = formType === "ADD";
  const shouldShowTabs = formIsAdd;
  const hasInitialValues = initialValues || readOnly;
  return (
    <Drawer
      style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      open={isOpen}
      anchor={i18n.language === "ar" ? "left" : "right"}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          {titleText || t(Strings.addBranchTitle)}
          <img
            onClick={handleToggleDrawer}
            src={require("../../../../assets/icon-close.svg").default}
          />
        </div>
        <form
          onKeyUp={handleDisableEnter}
          autoComplete="off"
          onKeyPress={handleDisableEnter}
          onSubmit={handleAddCashierSubmit}
          className={styles.form}
        >
          {shouldShowTabs && (
            <TabNavigation onChangeTab={handleChangeTab as any}>
              <TabItem title={t(Strings.addToBranchTitle)}>
                <FormFields
                  hasPOS={hasPOS}
                  isCallCenter={isCallCenter}
                  formType={formType}
                  t={t}
                  extraFieldsProps={extraFieldsProps || {}}
                  readOnly={readOnly}
                  branches={branches || []}
                />
              </TabItem>
              {hasCallCenterBranch ? (
                <TabItem title={t(Strings.addCallCenterTitle)}>
                  <FormFields
                    hasPOS={hasPOS}
                    formType={formType}
                    t={t}
                    extraFieldsProps={extraFieldsProps || {}}
                    readOnly={readOnly}
                    branches={branches || []}
                    isCallCenter={isCallCenter}
                  />
                </TabItem>
              ) : null}
            </TabNavigation>
          )}
          {hasInitialValues && (
            <FormFields
              hasPOS={hasPOS}
              formType={formType}
              t={t}
              isCallCenter={isCallCenter}
              extraFieldsProps={extraFieldsProps || {}}
              readOnly={readOnly}
              branches={branches || []}
            />
          )}
          {!readOnly && (
            <button type="submit" className={styles.btn}>
              {submittingData && (
                <ClipLoader sizeUnit={"px"} size={20} color={"white"} />
              )}
              {!submittingData && (buttonText || t(Strings.submit))}
            </button>
          )}
        </form>
      </div>
    </Drawer>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  passOnFormValues: (fn) => dispatch(passOnFormValuesRequest(fn)),
});
const mapStateToProps = (state: IRootReducerState) => ({
  branches: state.branchesReducer.allBranches,
});

const AddCashierForm = reduxForm({
  destroyOnUnmount: false,
  form: "addCashierForm",
  asyncBlurFields: ["phoneNumber"],
})(AddCashier as any);

export default hot(module)(
  connect<{}, IDispatchProps, IProps>(
    mapStateToProps as any,
    mapDispatchToProps
  )(translate("cashiers")(AddCashierForm as any) as any) as any
);

interface IFormFieldsProps extends InjectedTranslateProps {
  isCallCenter?: boolean;
  branches: IBranch[];
  readOnly?: boolean;
  extraFieldsProps: IProps["extraFieldsProps"];
  formType: FormTypes;
  hasPOS: boolean;
}

const FormFields: React.FC<IFormFieldsProps> = ({
  isCallCenter,
  branches,
  readOnly,
  extraFieldsProps,
  t,
  formType,
  hasPOS,
}) => {
  const requiredValidator = formType === "ADD" ? required : noop;
  return (
    <div style={{ padding: "0 3.5rem", marginTop: "5rem" }}>
      <Field
        name="phoneNumber"
        placeholder="01** *********"
        type="number"
        disabled={readOnly || formType === "EDIT"}
        component={RenderGenericField}
        label={t(Strings.phoneNumber)}
        validate={[...([required, isEmpty]), maxLength(70)]}
        {...extraFieldsProps.phoneNumber}
      />
      <div className={styles.separator} />
      <Field
        name="name"
        type="text"
        placeholder={t(Strings.cashierName)}
        disabled={readOnly}
        component={RenderGenericField}
        label={t(Strings.cashierName)}
        validate={[required, isEmpty, maxLength(70)]}
        {...extraFieldsProps.name}
      />
      <div className={styles.separator} />
      {!isCallCenter && (
        <>
          <Field
            name="branches"
            options={branches}
            disabled={readOnly}
            component={RenderChipsField}
            label={t(Strings.assignToBranches)}
            validate={[arrayNotEmpty]}
            {...extraFieldsProps.branches}
          />
          <div className={styles.separator} />
        </>
      )}
      {formType !== "VIEW" && (
        <Field
          name="password"
          type="password"
          disabled={readOnly}
          component={RenderPasswordField}
          label={t(Strings.password)}
          maxLength={32}
          validate={[requiredValidator, minLength(3), maxLength(32)]}
          {...extraFieldsProps.password}
        />
      )}
      <div className={styles.separator} />
      {hasPOS && (
        <Field
          name="integration_id"
          type="text"
          disabled={readOnly}
          placeholder="321"
          component={RenderGenericField}
          label={t(Strings.integrationId)}
          {...extraFieldsProps.integration_id}
        />
      )}
    </div>
  );
};