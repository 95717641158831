import * as actions from "../../constants";
import * as types from "../../types/wizard-types";
import { IBranch, PickupStatus } from "../../types/shared";
import { action } from "ts-action";
import { QRPortalTypes } from "../../Components/Pickup/lib/types";
import { AxiosError } from "axios";

export const getBranches = (params?): types.IGetBranchesAction => ({
  type: actions.getBranchesAction.requested,
  payload: params,
});

export const editBranchStatues = (values: {
  delivery_branches_ids?: any;
  pickup_branches_ids?: any;
}): types.IEditBranchStatuesAction => ({
  type: actions.editBranchStatuesAction,
  payload: {
    delivery_branches_ids: values.delivery_branches_ids,
    pickup_branches_ids: values.pickup_branches_ids,
  },
});

export const getBranchesSuccess = (
  branches: IBranch[]
): types.IGetBranchesSuccessAction => ({
  type: actions.getBranchesAction.fulfilled,
  payload: branches,
});

export const getBranchesFailure = (
  error: AxiosError
): types.IGetBranchesFailureAction => ({
  type: actions.getBranchesAction.rejected,
  payload: error,
});

export const addBranch = (branch): types.IAddBranchAction => ({
  type: actions.addBranchAction.requested,
  payload: branch,
});
export const clearPrevState = action(actions.clearPrevStateAction);
export const addBranchSuccess = (
  branch: IBranch
): types.IAddBranchSuccessAction => ({
  type: actions.addBranchAction.fulfilled,
  payload: branch,
});

export const addBranchFailure = (branch): types.IAddBranchFailureAction => ({
  type: actions.addBranchAction.rejected,
  payload: branch,
});

export const editBranch = (branch: IBranch): types.IEditBranchAction => ({
  type: actions.editBranchAction.requested,
  payload: branch,
});

export const editBranchSuccess = (
  branch: IBranch
): types.IEditBranchSuccessAction => ({
  type: actions.editBranchAction.fulfilled,
  payload: branch,
});

export const editBranchFailure = (branch): types.IEditBranchFailureAction => ({
  type: actions.editBranchAction.rejected,
  payload: branch,
});

export const deleteBranch = (branch): types.IDeleteBranchAction => ({
  type: actions.deleteBranchAction.requested,
  payload: branch,
});

export const deleteBranchSuccess = (
  branch: IBranch
): types.IDeleteBranchSuccessAction => ({
  type: actions.deleteBranchAction.fulfilled,
  payload: branch,
});

export const deleteBranchFailure = (
  branch
): types.IDeleteBranchFailureAction => ({
  type: actions.deleteBranchAction.rejected,
  payload: branch,
});

export const searchBranches = (name: string): types.ISearchBranchesAction => ({
  type: actions.ACTION_SEARCH_BRANCHES,
  payload: name,
});

export const editQRPortalBranchStatus = ({
  status,
  type,
  id,
}: {
  status: PickupStatus;
  type: QRPortalTypes;
  id: string;
}): types.IEditQRPortalBranchStatus => ({
  type: actions.ACTION_EDIT_QR_PORTAL_BRANCH_STATUS,
  payload: { status, type, id },
});