import * as React from "react";
import styles from "./styles.module.sass";
import { useCallback } from "react";

export interface ISelectItemProps {
  activePickup: boolean;
  activeDelivery: boolean;
  item: { name: string; value: string };
  onClick: (
    item: { name: string; value: string },
    type: string,
    newVal: boolean
  ) => void;
}

export const SelectItem: React.FC<ISelectItemProps> = (props) => {
  const { item, activeDelivery, activePickup, onClick } = props;

  const handlePickupClick = useCallback(() => {
    onClick(item, "Pickup", !activePickup);
  }, [onClick, item, activeDelivery]);
  const handleDeliveryClick = useCallback(() => {
    onClick(item, "Delivery", !activeDelivery);
  }, [onClick, item, activeDelivery]);
  return (
    <div
      className={`${styles.main} ${
        activeDelivery || activePickup ? styles.active : ""
      }`}
    >
      <div className={styles.text}>{item.name}</div>
      <div className={styles.checkBoxWrapper}>
        <div
          className={`${styles.checkBox} ${
            activePickup ? styles.activePickup : ""
          } ${styles.leftCheckBox}`}
          onClick={handlePickupClick}
        >
          <img src={require("../../../assets/path.svg").default} />
        </div>

        <div
          className={`${styles.checkBox} ${
            activeDelivery ? styles.activeDelivery : ""
          } ${styles.rightCheckBox}`}
          onClick={handleDeliveryClick}
        >
          <img src={require("../../../assets/path.svg").default} />
        </div>
      </div>
    </div>
  );
};
